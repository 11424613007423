@import "reset";
@import "layout";
@import "fonts";
@import "typography";
@import "tables";
@import "hacks";

@media screen and (max-width: 800px) {
	@import "media_small";
}

@media screen and (min-width: 800px) {
	@import "media_large";
}

#imprint-table, #imprint-table * {
	border: none;
	
	td.head {
		text-align: right;
		padding-left: 0;
	}
}

div {
//	border: 1px dotted lightgrey;
}
