$table_border: 1px solid grey;
$table_h_pad: 0.5em;
$table_v_pad: 0.2em;

table {
	font-family: $default_font;
	border: $table_border;
	
	tr {
	
	}
	
	th {
		background: #ddd;
		text-align: center;
		font-weight: bold;
		border: $table_border;
		padding: $table_v_pad $table_h_pad $table_v_pad $table_h_pad;
	}
	
	td {
		text-align: left;
		border: $table_border;
		padding: $table_v_pad $table_h_pad $table_v_pad $table_h_pad;
	}
	
	
}
