$tscale: 0.35;

div.image-box {
//	display: none;
	a {
		img {
			width: auto;
			height: auto;
			max-width: 100px;
		}
	}
}

#wrap {
	min-width: 300px;
	padding-left: 0.7em;
	padding-right: 0.7em;
}

#header {
	padding-top: 5em;
	
	div#logo  {
		height: 120px;
		width: 97px;
		background: url(../images/logo-120.png);
	}
}

#title {
	margin-left: 100px;
	
	#title-center {

		width: $tscale*31em;
		margin-top: -0.75em;
		
		a:link,a:visited,a:hover,a:active,a:focus {
			
			#title_sub {
				font-size: $tscale*2.5em;
			}

			#title_main {
				font-size: $tscale*10em;	
			}
		}
	}

	#menu {
		margin-left: -100px;
		clear: both;
		
		ul {
			margin-top: 1em;
			
			li.menu-item {
				margin-left: 0;
				margin-top: 0.3em;
				span {
					font-size: 0.75em;
				}
			}

		}
	}
}

div#intro-leftcol {
	/*
	width: 65%;
	float: left;
	*/
	padding-right: 0;
	position: relative;
}

div#intro-rightcol {

	border-top: $default-border;
	width: 100%;
	position: relative;
}
