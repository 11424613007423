$fonts-base: "fonts";

@mixin font-def($subdir, $name, $cssname, $weight, $style) {
	@font-face {
		font-family: "#{$cssname}";
		src: url("#{$fonts-base}/#{$subdir}/#{$name}.eot");
		src: url("#{$fonts-base}/#{$subdir}/#{$name}.eot?#iefix") format('embedded-opentype'),
				url("#{$fonts-base}/#{$subdir}/#{$name}.woff") format('woff'),
				url("#{$fonts-base}/#{$subdir}/#{$name}.ttf") format('truetype'),
				url("#{$fonts-base}/#{$subdir}/#{$name}.svg") format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}

@include font-def("existence/light", "Existence-Light-webfont", "existence", normal, normal);
@include font-def("worstveldsling/normal", "WorstveldSling-webfont", "worstveld", normal, normal);
@include font-def("worstveldsling/italic", "WorstveldSling-webfont", "worstveld", normal, italic);

@include font-def("open-sans/light", "OpenSans-Light-webfont", "opensans", 100, regular);
@include font-def("open-sans/light-italic", "OpenSans-LightItalic-webfont", "opensans", 100, italic);
