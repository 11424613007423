$tscale: 0.7;

#wrap {
	min-width: 800px;
}

#header {
	padding-top: 5em;
	div#logo  {
		height: 200px;
		width: 162px;
		background: url(../images/logo-200.png);
	}
	height: 12em;
}

#title {
	margin-left: 165px;
	
	#title-center {
		float: left;
		width: $tscale*31em;
		margin-top: -0.75em;
		
		a:link,a:visited,a:hover,a:active,a:focus {
			
			#title_sub {
				font-size: $tscale*2.5em;
			}

			#title_main {
				font-size: $tscale*10em;	
			}
		}
	}
	
	#menu {
		float: right;
		
		ul {

			li.menu-item {
				
				margin: 0;
				float: left;
				width: 7em;
				padding-left: 0.5em;
				padding-right: 0.5em;
				border-left: 1px solid lightgrey;
				height: 4em;

				span.hidden-paren {
					@include skiplink;
				}

				span {
					display: block;
					font-size: 0.75em;
				}
			}

		}
	}
}
