$footer-height: 5em;
$default-border: 1px solid #ccc;
$image-border: 1px solid rgb(190,190,190);
$image-box-margin: 1em;
$image-box-padding: 10px;
$image-box-topmargin: 0.3em;
$wrap-padding: 2em;

@mixin default-image-box() {
	background-color:white;
	padding:$image-box-padding;
	//width: auto;
	margin-top: $image-box-topmargin;
	margin-bottom: 0.5em;
	background: rgb(243,243,243);
	.image-footer {
		font-size: 0.8em;
		height: 1.1em;
	}
	border: $image-border;
	
	img {
		border: $image-border;
		background: white;
	}
	
	a:link,a:visited,a:hover,a:focus {
		outline-style: none;
		border: none;
	}
}

div.image-box.left {
	@include default-image-box;
	float: left;
	margin-right: $image-box-margin;
}

div.image-box.right {
	@include default-image-box;
	float: right;
	margin-left: $image-box-margin;
}

div.image-box.multi {
	@include default-image-box;
	margin-top: 2 * $image-box-margin;
	margin-bottom: 2 * $image-box-margin;
}

div.image-box.multi:first-child {
	margin-top: 0;
}

div.image-box.multi:last-child {
	margin-bottom: 0;
}

div.multiimg.left {
	float: left;
	margin-right: $image-box-margin;
	margin-top: $image-box-topmargin;
	margin-bottom: 0.5em;
}

div.multiimg.right {
	float: right;
	margin-left: $image-box-margin;
	margin-top: $image-box-topmargin;
	margin-bottom: 0.5em;
}

@mixin centerbox() {
	width: 70%;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

html, body {
	height: 100%;
	background: #DDD;
}


#wrap {
	min-height: 100%;
	@include centerbox;
	border-left: $default-border;
	border-right: $default-border;
	padding-left: $wrap-padding;
	padding-right: $wrap-padding;
	background: white;
}

#main {
	overflow:auto;
	padding-bottom: $footer-height; /* must be same height as the footer */
}  

img#intro-img {
	width: 100%; 
	height: auto;
}

#header {
	padding-top: 5em;
	div#logo  {
		margin-top: -3em;
		float: left;
	}
	border-bottom: $default-border;
}

#footer {
	position: relative;
	margin-top: -$footer-height + 1em;/* negative value of footer height */
	padding-top: 1em;
	height: $footer-height - 2em;
	clear:both;
	@include centerbox;
	border-top: $default-border;
	text-align: center;
}

$intro-col-width: 16em;

div#intro-leftcol {
	/*
	width: 65%;
	float: left;
	*/
	padding-right: $intro-col-width;
	position: relative;
}

div#intro-rightcol {

	//margin-left: 65%;
	width: $intro-col-width - 2em;
	position: absolute;
	right: 0;
	top: -0.3em;
}

#title {
	
	#title-center {
		position: relative;
		text-align: right;
		color: black;
		vertical-align: top;
		margin-top: -0.75em;
		
		a:link,a:visited,a:hover,a:active,a:focus {
			color: black;
			font-weight: normal !important;
			font-size: 1em !important;
			border: none;
			outline-style: none;
			
			#title_sub {
				font-family: "worstveld";
				text-shadow: none;
				position: absolute;
				right: 0.2em;
				top: 0.28em;
			}

			#title_main {
				#kern {
					position: relative;
					left: 0.12em;
					font-family: "worstveld";
					text-shadow: none;
				}
				font-family: "worstveld";
				text-shadow: none;
			}
		}
	}
	
	#menu {
		
		ul {
			
			li.menu-item {
				list-style-type: none;
				a {
					text-shadow: none;
					color: #666;
					font-size: 1.0em;
				}

				span {
					text-shadow: none;
				}
			}

			li.menu-item:first-child {
				border-left: none;
			}
		}
	}
}

#gmap {
	margin-top: 3.1em;
	width: 60%;
	height: 25em;
	float: right;
//	border: $default-border;
}

@mixin skiplink {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.skiplink {
	@include skiplink;
}
