$heading_font: "existence";
$default_font: "opensans";
$default_indent: 1.5em;
$default_border: 1px solid #aaa;


@mixin heading_margin($mscale) {
	margin-top: $mscale;
	margin-bottom: 0.3 * $mscale;
}

* { 
	font-family: $default_font;
	font-size: 0.9em;
}

h1 {
	font-family: $heading_font;
	font-size: 2em;
	@include heading_margin(1em);
}

h1#pagetitle {
	font-size: 1em;
	font-weight: normal;
	color: black;
}

h2 {
	font-family: $heading_font;
	font-size: 1.7em;
	@include heading_margin(1em);
}

h3 {
	font-family: $heading_font;
	font-size: 1.5em;
	@include heading_margin(1em);
}

h4 {
	font-family: $heading_font;
	font-size: 1.2em;
	@include heading_margin(1em);
}

h5 {
	font-family: $heading_font;
	font-size: 1.1em;
	@include heading_margin(1em);
}

h6 {
	font-family: $heading_font;
	font-size: 1em;
	@include heading_margin(1em);
}

dt {
	font-weight: bolder;
}

dd {
	margin-left: $default_indent;
}

ol li {
	margin-left: $default_indent + 0.2em;
	list-style-type: decimal;
	list-style-position: outside;
}

ul li {
	margin-left: $default_indent + 0.2em;
	list-style-type: disc;
	list-style-position: outside;
}

legend {
	font-weight: bolder;
}

div#menu ul li a.activepage {
	font-style: italic;
}

#main p {
	margin-top: 0.3em;
	margin-bottom: 0.5em;
	text-align: justify;
}

a:active,a:link,a:visited,a:hover,a:focus {
	text-decoration: none;
	color: rgb(100,100,160);
	outline-style: none;
}


pre {
	border: $default_border;
	line-height: 1em;
	
	code {
		font-family: monospace;
		text-shadow: none;
	}
	margin-top: 1em;
	margin-bottom: 1em;
	background: #ccc;
	width: auto;
}

blockquote {
	margin: $default_indent;
	font-style: italic;
}
#last-mod {
	font-size: 0.8em;
	vertical-align: top;
}
